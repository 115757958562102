import api from '.'
import { AuthHeaders } from '../../interfaces/Auth'
import { store } from '../../store'
import { updateAuthHeaders } from '../../store/modules/auth/actions'
import { RootState } from '../../store/modules/rootReducer'

export function getStoreAuthHeaders(): AuthHeaders | null {
  const globalStore = store.getState() as RootState
  const { headers } = globalStore.auth

  return headers
}

export function setAxiosAuthHeaders({
  uid,
  client,
  accessToken,
  tokenType,
  expiry,
}: AuthHeaders) {
  if (uid) api.defaults.headers.common.uid = uid
  if (client) api.defaults.headers.common.client = client
  if (accessToken) api.defaults.headers.common['Access-Token'] = accessToken
  if (tokenType) api.defaults.headers.common['Token-Type'] = tokenType
  if (expiry) api.defaults.headers.common.expiry = expiry
}

export function clearAxiosAuthHeaders() {
  delete api.defaults.headers.common.uid
  delete api.defaults.headers.common.client
  delete api.defaults.headers.common.accessToken

  window.location.reload()
}

export function refreshAuthHeaders(responseHeaders: any) {
  const { uid, client, accessToken, tokenType, expiry } = responseHeaders

  const responseAuthHeaders: AuthHeaders = {
    uid,
    client,
    accessToken,
    tokenType,
    expiry,
  }

  const oldAuthHeaders = getStoreAuthHeaders()

  const newAuthHeaders = {
    uid: responseAuthHeaders.uid || oldAuthHeaders?.uid || '',
    client: responseAuthHeaders.client || oldAuthHeaders?.client || '',
    accessToken:
      responseAuthHeaders.accessToken || oldAuthHeaders?.accessToken || '',
    tokenType: responseAuthHeaders.tokenType || oldAuthHeaders?.tokenType || '',
    expiry: responseAuthHeaders.expiry || oldAuthHeaders?.expiry || '',
  }

  setAxiosAuthHeaders(newAuthHeaders)
  store.dispatch(updateAuthHeaders(newAuthHeaders))
}
