import axios from 'axios'
import applyCaseMiddleware from 'axios-case-converter'

import { applyInterceptors } from './interceptors'

const csrfToken = document
  .querySelector('[name=csrf-token]')
  ?.getAttribute('content')

const api = applyCaseMiddleware(
  axios.create({
    baseURL: `${window.location.origin}`,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-CSRF-Token': csrfToken,
    },
  }),
)

applyInterceptors(api)

export default api
