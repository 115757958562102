import { AxiosInstance } from 'axios'
import { store } from '../../store'
import { signOut } from '../../store/modules/auth/actions'
import { handleCatchError } from './errors'
function requestInterceptor(api: AxiosInstance) {
  if (api) {
    return api.interceptors.request.use((request) => {
      return request
    })
  } else {
    return {}
  }
}

function responseInterceptor(api: AxiosInstance) {
  return api.interceptors.response.use(
    (response) => {
      return response
    },
    (error) => {
      const state = store.getState()

      if (
        error.code === 'ERR_BAD_REQUEST' &&
        error.message.includes('401') &&
        state.auth.isSigned === true
      ) {
        store.dispatch(signOut())
      }
      handleCatchError(error)
      return error
    },
  )
}

export function applyInterceptors(api: AxiosInstance) {
  if (api) {
    requestInterceptor(api)
    responseInterceptor(api)
  }
}
