import { REHYDRATE } from 'redux-persist/lib/constants'
import { all, call, put, takeEvery, takeLatest } from 'redux-saga/effects'

import {
  clearAxiosAuthHeaders,
  setAxiosAuthHeaders,
} from '../../../config/api/utils'

import { AxiosResponse } from 'axios'
import { getResponseErrorMessage } from '../../../config/api/errors'
import { User } from '../../../interfaces/User'
import { AuthService } from '../../../services/api/Auth'
import { signFailure, signInSuccess, signOut } from './actions'
import { AUTHENTICATION_EXPIRED, SIGN_IN_REQUEST, SIGN_OUT } from './types'

export function* onSignInRequest(token) {
  try {
    let res = {} as AxiosResponse

    if (token.payload.provider === 'email') {
      res = yield call(AuthService.signIn, {
        email: token.payload.email,
        password: token.payload.password,
      })
    } else {
      res = yield call(AuthService.socialLogin, token.payload.token)
    }

    const errorMessage = getResponseErrorMessage(res)
    if (errorMessage) {
      yield put(signFailure(errorMessage))
      return errorMessage
    }
    const userSignedData: any =
      token.payload.provider === 'email' ? res.data.data : res.data

    const user: User = {
      ...userSignedData,
    }
    const { uid, client, expiry } = res.headers

    const accessToken = res.headers['access-token'] || res.headers.accessToken
    const tokenType = res.headers['token-type'] || res.headers.tokenType

    setAxiosAuthHeaders({ uid, client, accessToken, tokenType, expiry })
    const headers = { uid, client, accessToken, tokenType, expiry }
    const signedIn = { user, headers }
    yield put(signInSuccess(signedIn))
  } catch (error) {
    yield put(signFailure())
  }
}

export function* onRehydrate({ payload }: any) {
  if (!payload || !payload.auth) return

  const { isLoading, isSigned, headers } = payload.auth

  if (!isSigned && isLoading) {
    yield put(signOut())
    return
  }

  if (headers) {
    const { uid, client, accessToken, tokenType, expiry } = headers
    setAxiosAuthHeaders({ uid, client, accessToken, tokenType, expiry })
  }
}

export function* onSignOut() {
  yield call(AuthService.logout)
  yield call(clearAxiosAuthHeaders)
}

export function* onAuthenticationExpired() {
  yield call(AuthService.logout)
  yield call(signOut)
  yield call(clearAxiosAuthHeaders)
}

export default all([
  takeLatest(SIGN_IN_REQUEST, onSignInRequest),
  takeLatest(REHYDRATE, onRehydrate),
  takeEvery(SIGN_OUT, onSignOut),
  takeEvery(AUTHENTICATION_EXPIRED, onAuthenticationExpired),
])
