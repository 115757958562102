import api from '../../../config/api'

const resource = '/auth'

export const endpointsAuth = {
  socialLogin: `/omniauth/request`,
  signIn: `${resource}/sign_in`,
  signOut: `${resource}/sign_out`,
  signUp: `/users/signup`,
  resetPassword: `${resource}/password`,
  changePassword: `${resource}/password`,
}

export const AuthService = {
  signUp: (data) => api.post(endpointsAuth.signUp, data),
  logout: () => api.delete(endpointsAuth.signOut),
  socialLogin: (token: string) =>
    api.post(endpointsAuth.socialLogin, { token }),
  signIn: (data) => api.post(endpointsAuth.signIn, data),
  resetPassword: (data) => api.put(endpointsAuth.resetPassword, data),
  changePassword: (data) => api.put(endpointsAuth.changePassword, data),
}
